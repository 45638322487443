import "./style.scss";
import { debounce } from "lodash";
import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";

gsap.registerPlugin(SplitText);

// gsap.config({nullTargetWarn:false});
var imagesLoaded = require("imagesloaded");

import compass from "./images/compass.png";
import writingtop from "./images/writingtop.png";
import balloon from "./images/balloon.png";
import writingleft from "./images/writingleft.png";
import mapright from "./images/mapright.png";
import castle from "./images/castle.png";
import watch from "./images/watch.png";
import mapleft from "./images/mapleft.png";
import videoPlaceholder from "./images/video-placeholder.jpg";

setTimeout(function () {
	var split = new SplitText(".split", {
		type: "lines",
		linesClass: "fade-in-up",
		reduceWhiteSpace: false,
	});

	let intro = gsap
		.timeline({ paused: true })
		.to(".back-img", { autoAlpha: 1 })
		.addLabel("start")
		.set(".bg", { autoAlpha: 1 })
		.to(
			".back-img",
			{
				maskPosition: "100% 0",
				ease: "steps(29)",
				duration: 2,
				onComplete: () => {
					gsap.set(".back-img", { autoAlpha: 0 });
				},
			},
			"start"
		)
		.to(
			".back-img",
			{
				scale: 1.5,
				duration: 2,
			},
			"start"
		)
		.to("main", {
			opacity: 1,
			duration: 1,
			// delay: 3,
		})
		.addLabel("content")
		.to(
			".fade-in-up",
			{
				duration: 1,
				autoAlpha: 1,
				y: 0,
				stagger: 0.4,
			},
			"-=1"
		)
		.from(
			"#bg-imgs img",
			{
				autoAlpha: 0,
				y: 0,
				stagger: 0.5,
				duration: 1,
			},
			"content+=.5"
		);

	imagesLoaded(".back-img", { background: true }, function () {
		intro.play();
	});
}, 300);

// gsap.from(split.lines, {
// 	duration: 1,
// 	x: 200,
// 	autoAlpha: 0,
// 	ease: "power3",
// 	stagger: 0.05,
// });

// const queryString = window.location.search;
// const urlParams = new URLSearchParams(queryString);
// if (!urlParams.has("alt")) {

let imgs = document.querySelectorAll("#bg-imgs .ani1");

imgs.forEach(function (element) {
	document.addEventListener("mousemove", function (event) {
		var xPos = event.clientX / window.innerWidth - 0.5,
			yPos = event.clientY / window.innerHeight - 0.5,
			box = element;

		gsap.to(box, {
			duration: 3,
			y: yPos * 50,
			x: xPos * 50,
			ease: "Power1.easeOut",
			stagger: 0.3,
		});
	});
});

let imgs2 = document.querySelectorAll("#bg-imgs .ani2");

imgs2.forEach(function (element) {
	document.addEventListener("mousemove", function (event) {
		var xPos = event.clientX / window.innerWidth - 0.5,
			yPos = event.clientY / window.innerHeight - 0.5,
			box = element;

		gsap.to(box, {
			duration: 3,
			y: xPos * 50,
			x: yPos * 50,
			ease: "Power1.easeOut",
			stagger: 0.3,
		});
	});
});

let imgs3 = document.querySelectorAll("#bg-imgs .ani3");

imgs3.forEach(function (element) {
	document.addEventListener("mousemove", function (event) {
		var xPos = event.clientX / window.innerWidth - 0.5,
			yPos = event.clientY / window.innerHeight - 0.5,
			box = element;

		gsap.to(box, {
			duration: 3,
			y: xPos * -50,
			x: yPos * 50,
			ease: "Power1.easeOut",
			stagger: 0.3,
		});
	});
});

// } else {
// 	let compass = document.querySelector("#compass");
// 	document.addEventListener("mousemove", function (event) {
// 		var xPos = event.clientX / window.innerWidth - 0.5,
// 			yPos = event.clientY / window.innerHeight - 0.5,
// 			box = compass;

// 		gsap.to(box, {
// 			duration: 1,
// 			rotationY: yPos * -40,
// 			rotationX: xPos * 40,
// 			ease: "Power1.easeOut",
// 		});
// 	});

// 	let balloon = document.querySelector("#balloon");
// 	document.addEventListener("mousemove", function (event) {
// 		var xPos = event.clientX / window.innerWidth - 0.5,
// 			yPos = event.clientY / window.innerHeight - 0.5,
// 			box = balloon;

// 		gsap.to(box, {
// 			duration: 1,
// 			y: yPos * 50,
// 			x: xPos * 50,
// 			ease: "none",
// 		});
// 	});

// 	let watch = document.querySelector("#watch");
// 	document.addEventListener("mousemove", function (event) {
// 		var xPos = event.clientX / window.innerWidth - 0.5,
// 			yPos = event.clientY / window.innerHeight - 0.5,
// 			box = watch;

// 		gsap.to(box, {
// 			duration: 1,
// 			rotationZ: xPos * 50,
// 			// rotationX: yPos * -50,
// 			ease: "Power1.easeOut",
// 		});
// 	});
// }
